import {request} from "../../../util/Request";
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'load_number', label: this.$t('title.loadNumber'), show: true},
                    {id: 'client', label: this.$t('title.client'), show: true},
                    {id: 'supplier_carrier', label: this.$t('title.supplierCarrier'), show: true},
                    {id: 'supplier_compound', label: this.$t('title.supplierCompound'), show: true},
                    {id: 'from_location', label: this.$t('title.loadingPoint'), show: true},
                    {id: 'to_location', label: this.$t('title.unLoadingPoint'), show: true},
                    {id: 'driver', label: this.$t('title.driver'), show: true},
                    {id: 'transport_vehicle', label: this.$t('title.transportVehicle'), show: true},
                    {id: 'qty', label: this.$t('title.qty'), show: true},
                    {id: 'actual_loading_time', label: this.$t('title.actualLoadingDate'), show: true},
                    {id: 'updated_at', label: this.$t('title.modifiedAt'), show: true},
                ],
                headers: {
                    [this.$t('column.loadNumber')]: 'load_number',
                    [this.$t('column.client')]: 'client',
                    [this.$t('column.supplierCarrier')]: 'supplier_carrier',
                    [this.$t('column.supplierCompound')]: 'supplier_compound',
                    [this.$t('column.loadingPoint')]: 'from_location',
                    [this.$t('column.unLoadingPoint')]: 'to_location',
                    [this.$t('column.driver')]: 'driver',
                    [this.$t('column.transportVehicle')]: 'transport_vehicle',
                    [this.$t('column.qty')]: 'qty',
                    [this.$t('column.actualLoadingDate')]: 'actual_loading_time',
                    [this.$t('column.modifiedAt')]: 'updated_at',
                }
            },
        }
    },
    methods: {
        async handleSingleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && columns.includes(index)) {
                    headers[item] = index
                }
            })

            this.exportModal.headers = headers

            const response = await request({
                method: "get",
                url: `archives/reexport/loads/excel/detail/${this.exportModal.id}`,
            })

            const {data} = response
            const rows = [];
            const row = _.pick({
                load_number: this.$global.val(data.load_number),
                client: this.$global.val((data.client || {}).company_name),
                supplier_carrier: this.$global.val((data.supplier_carrier || {}).name),
                supplier_compound: this.$global.val((data.supplier_compound || {}).name),
                from_location: data.reexport_load_orders[0] ? this.$global.val((data.reexport_load_orders[0].from_location || {}).city) : "",
                to_location: data.reexport_load_orders[0] ? this.$global.val((data.reexport_load_orders[0].to_location || {}).city) : "",
                driver: this.$global.val((data.driver || {}).name),
                transport_vehicle: (data.transport_vehicle ? (data.transport_vehicle.truck_plate_number ? data.transport_vehicle.truck_plate_number : "") + (data.transport_vehicle.trailer_plate_number ? "|" + data.transport_vehicle.trailer_plate_number : "") : ""),
                qty: this.$global.val(data.qty),
                actual_loading_time: (data.actual_loading_time ? this.$global.val(this.$global.utcDateToLocalDate(data.actual_loading_time)) : ""),
                updated_at: this.$global.val(this.$global.utcDateToLocalDate(data.updated_at)),
            }, columns)
            rows.push(row)

            return rows;
        },
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })

            this.exportModal.headers = headers

            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams}
            const response = await request({
                method: "get",
                url: 'archives/reexport/loads/excel',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response

            const rows = _.map(data, (item, index) => _.pick({
                load_number: this.$global.val(item.load_number),
                client: this.$global.val((item.client || {}).company_name),
                supplier_carrier: this.$global.val((item.supplier_carrier || {}).name),
                supplier_compound: this.$global.val((item.supplier_compound || {}).name),
                from_location: item.reexport_load_orders[0] ? this.$global.val((item.reexport_load_orders[0].from_location || {}).city) : "",
                to_location: item.reexport_load_orders[0] ? this.$global.val((item.reexport_load_orders[0].to_location || {}).city) : "",
                driver: this.$global.val((item.driver || {}).name),
                transport_vehicle: (item.transport_vehicle ? (item.transport_vehicle.truck_plate_number ? item.transport_vehicle.truck_plate_number : "") + (item.transport_vehicle.trailer_plate_number ? "|" + item.transport_vehicle.trailer_plate_number : "") : ""),
                qty: this.$global.val(item.qty),
                actual_loading_time: (item.actual_loading_time ? this.$global.val(this.$global.utcDateToLocalDate(item.actual_loading_time)) : ""),
                updated_at: this.$global.val(this.$global.utcDateToLocalDate(item.updated_at)),
            }, columns))
            return rows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
    },
}
